import React from 'react';

const FormPage: React.FC = () => {
  return (
    <div>
      <h2>SUBSCRIBE FOR BILITANONNE UPDATES AND NEWSLETTERS</h2>
      <iframe
        src="https://form.jotform.com/242234028147349"
        width="100%"
        height="500px"
        frameBorder="0"
        title="Subscription Form"
        style={{ border: 'none' }}
      ></iframe>
    </div>
  );
};

export default FormPage;